import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Page = () => (
  <Layout>
    <Seo title="Hva skiller Mente fra andre todo-apper?" />
    <div className="longtext">
      <h2>Hva skiller Mente fra andre todo-apper?</h2>

      <p>
        Mente har det "alle" andre har, i tillegg til smarte funksjoner som:
      </p>
      <ul>
        <li>
          <Link to="/features/capture">
            Superenkle måte å få ting ut av hodet på
          </Link>
        </li>
        <li>
          <Link to="/features/organize">En listestruktur</Link> basert på{" "}
          <a href="https://gettingthingsdone.com/" target="_blank">
            Getting Things Done
          </a>
          /GTD
        </li>
        <li>
          <Link to="/features/reflection">Refleksjon</Link>
        </li>
        <li>
          <Link to="/features/routines">
            Muligheten til å bygge egne rutiner
          </Link>
        </li>
        <li>
          <Link to="/features/countup">En egen type gjentakende oppgaver</Link>,
          som lar deg følge med på hvor lenge det er siden du gjorde noe sist
        </li>
        <li>Guide til å sette mål og idégenerere tiltak</li>
        <li>Guide til å tømme hodet ditt for alt du tenker på</li>
        <li>
          Bibliotek: en egen liste for alt du har lyst til å gjøre, men som du
          ikke må gjøre: serier å se, bøker å lese og oppskrifter å teste ut
        </li>
      </ul>

      <p>
        Og i tillegg vi er et lite, norsk selskap, som er gjerne mottar innspill
        på <a href="mailto:hello@mente.app">hello@mente.app</a> 🤞
      </p>
    </div>
  </Layout>
)

export default Page
